<template>
  <LoadListView
    v-model="loadStatus"
    :list="list"
    :total="total"
    :show-total-view="false"
    v-bind="$attrs"
    @load="getList"
  >
    <div
      v-for="item in list"
      :key="item.id"
      class="video-item"
      @click="gotoVideoDetail(item.id)"
    >
      <div class="video-icon" :class="{ active: item.onLine }">
        <i
          class="iconfont iconfont-shipin1"
          @click.stop="$emit('play-line', item)"
        ></i>
      </div>
      <div class="video-name">
        {{ item.videoName }}
      </div>
      <div class="video-other-item">
        <span v-if="item.departmentName" class="video-part"
          ><i class="iconfont iconfont-bumen"></i>
          {{ item.departmentName }}</span
        >
        <span v-if="item.relatedDevice" class="device-info"
          ><i class="iconfont iconfont-jianceduixiang"></i
          >{{ item.relatedDevice ? item.relatedDevice.join(",") : "" }}</span
        >
        <span
          v-if="item.levelCode"
          class="danger-source"
          :class="dangerLevelObj[item.levelCode]"
          >危险源</span
        >
      </div>
    </div>
  </LoadListView>
</template>

<script>
export default {
  props: {
    api: {
      type: Function,
      default: () => Promise.resolve({ total: 0, list: [] })
    }
  },
  data() {
    return {
      loadStatus: "loading",
      query: {
        size: 10,
        page: 1
      },
      list: [],
      total: 0,
      error: false
    };
  },
  computed: {
    dangerLevelObj() {
      return {
        100311: "level1",
        100312: "level2",
        100313: "level3",
        100314: "level4"
      };
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    gotoVideoDetail(id) {
      this.$router.push({ name: "VideoDetail", params: { id } });
    },
    getList(refresh = false) {
      if (refresh) {
        this.query.page = 1;
        this.list = [];
        this.total = 0;
        this.loadStatus = "loading";
      }

      //待处理没有分页
      this.api(this.query)
        .then(({ list = [], total = 0 }) => {
          this.list.push(...list);
          this.query.page++;
          this.total = total;
          this.loadStatus = "success";
        })
        .catch(error => {
          console.log(error);
          this.loadStatus = "error";
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.video-item {
  padding: 11px 16px 13px 16px;
  position: relative;
  border-bottom: 8px solid #eceef2;
  .video-icon {
    position: absolute;
    right: 16px;
    top: 8px;
    .iconfont {
      color: #d9dbe1;
      font-size: 24px;
    }
    &.active {
      .iconfont {
        color: #1676ff;
      }
    }
  }
  .video-name {
    color: #3b4664;
    font-size: 14px;
    font-weight: 500;
    padding-right: 30px;
    line-height: 16px;
  }
  .video-other-item {
    margin-top: 10px;
    color: #3b4664;
    font-size: 12px;
    span:first-child {
      margin-right: 24px;
    }
    .device-info {
      display: inline-block;
      max-width: 50%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: top;
    }
    .iconfont {
      margin-right: 2px;
      color: #aeb3c0;
    }
    .danger-source {
      position: relative;
      display: inline-block;
      transform: scale(0.9);
      padding: 2px 4px;
      font-size: 12px;
      margin-left: 4px;
      vertical-align: top;
      &::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 200%;
        height: 200%;
        border-radius: 2px;
        transform: scale(0.5);
        transform-origin: 0 0;
      }
      &.level1 {
        &::after {
          border: 1px solid #f13030;
        }
        color: #f13030;
        background-color: #ffdddd;
      }
      &.level2 {
        &::after {
          border: 1px solid #ff6d1e;
        }
        color: #ff6d1e;
        background-color: #ffe7db;
      }
      &.level3 {
        &::after {
          border: 1px solid #edc400;
        }
        color: #edc400;
        background-color: #fff8d7;
      }
      &.level4 {
        &::after {
          border: 1px solid #1580ff;
        }
        color: #1580ff;
        background-color: #dcecff;
      }
    }
  }
}
</style>
