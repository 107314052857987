<template>
  <div class="danger-detail">
    <van-nav-bar
      :title="detailInfo.name"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    />
    <van-tabs v-model="activeName">
      <van-tab title="实时监测" :name="MONITOR">
        <Monitor />
      </van-tab>
      <van-tab title="感知报警" :name="ALARM">
        <Alarm />
      </van-tab>
      <van-tab title="监控视频" :name="VIDEO">
        <VideoTab />
      </van-tab>
      <van-tab title="联锁投切" :name="INTERLOCK">
        <Interlock />
      </van-tab>
      <van-tab title="安全承诺" :name="SECURITY">
        <Security />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import Monitor from "./detailTabs/Monitor/Monitor.vue";
import Alarm from "./detailTabs/Alarm/Alarm.vue";
import VideoTab from "./detailTabs/VideoTab/VideoTab.vue";
import Interlock from "./detailTabs/Interlock/Interlock.vue";
import Security from "./detailTabs/Security/Security.vue";

import { getDangerDetail } from "./api";

const MONITOR = "MONITOR";
const ALARM = "ALARM";
const VIDEO = "VIDEO";
const INTERLOCK = "INTERLOCK";
const SECURITY = "SECURITY";

export default {
  components: {
    Monitor,
    Alarm,
    VideoTab,
    Interlock,
    Security
  },
  data() {
    return {
      activeName: MONITOR,
      detailInfo: {}
    };
  },
  computed: {
    MONITOR: () => MONITOR,
    ALARM: () => ALARM,
    VIDEO: () => VIDEO,
    INTERLOCK: () => INTERLOCK,
    SECURITY: () => SECURITY
  },
  watch: {
    activeName() {
      this.$router.replace({
        path: this.$route.path,
        query: { activeName: this.activeName }
      });
    }
  },
  created() {
    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName;
    }
  },
  mounted() {
    getDangerDetail(this.$route.params.id).then(res => {
      this.detailInfo = res;
    });
  }
};
</script>
