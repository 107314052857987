<template>
  <div>
    <van-search
      v-model="searchData.searchName"
      placeholder="请输入摄像头名称或IP关键字"
      @search="onSearch"
      @clear="onSearch"
    >
      <template #left-icon>
        <van-icon name="sousuo" class-prefix="iconfont" class="search-icon" />
      </template>
    </van-search>
    <VideoList ref="video-list" :api="getVideoList" @play-line="playLine" />
    <VideoPopup v-model="showVideo" :model="model" />
  </div>
</template>

<script>
import VideoList from "../../components/VideoList.vue";
import VideoPopup from "@/views/video/components/videoPopup.vue";

import { getVideoList } from "../../api";
export default {
  components: {
    VideoList,
    VideoPopup
  },
  data() {
    return {
      searchData: {
        keyInfo: "",
        searchName: ""
      },
      showVideo: false,
      model: {
        videoName: "",
        orgCode: "",
        nvrAddr: "",
        channelAddr: "",
        cameraIP: ""
      }
    };
  },
  methods: {
    playLine(item) {
      const obj = {
        ...item,
        orgCode: this.userInfo.orgCode
      };
      this.model = obj;

      this.showVideo = true;
    },
    getVideoList(query = {}) {
      return getVideoList({
        ...this.searchData,
        ...query,
        dangerIdList: this.$route.params.id,
        orgCode: this.userInfo.orgCode
      });
    },
    onSearch() {
      this.$refs["video-list"].getList(true);
    }
  }
};
</script>
