<template>
  <div>
    <van-search
      v-model="searchData.fuzzyName"
      placeholder="请输入报警点位名称"
      @search="onSearch"
      @clear="onSearch"
    >
      <template #left-icon>
        <van-icon name="sousuo" class-prefix="iconfont" class="search-icon" />
      </template>
    </van-search>
    <date-time
      v-model="dateTime"
      :time-close="false"
      type="datetime"
      format="{y}-{m}-{d} {h}:{i}"
      class="alarm-search-date"
    />
    <AlarmList ref="alarm-list" :api="getAlarmList" />
  </div>
</template>

<script>
import AlarmList from "../../components/AlarmList.vue";

import { getAlarmList } from "../../api";
import { parseTime } from "@/utils";
export default {
  components: {
    AlarmList
  },
  data() {
    return {
      dateTime: {
        start: parseTime(new Date(), "{y}-{m}-{d}") + " 00:00",
        end: parseTime(Date.now(), "{y}-{m}-{d}") + " 23:59"
      },
      searchData: {
        fuzzyName: ""
      }
    };
  },
  watch: {
    dateTime: {
      handler(val) {
        console.log("dateTime -> val", val);
        if (val.start && val.end) {
          this.searchData.startTime = this.formatDate(val.start);
          this.searchData.endTime = this.formatDate(val.end);
          this.onSearch();
        }
        if (!val.start && !val.end) {
          this.searchData.startTime = "";
          this.searchData.endTime = "";
          this.onSearch();
        }
      },
      deep: true
    }
  },
  methods: {
    formatDate(date) {
      return new Date(date).getTime();
    },
    getAlarmList(query = {}) {
      return getAlarmList({
        ...this.searchData,
        ...query,
        dangerIdList: this.$route.params.id,
        startTime: 1629820800000,
        endTime: 1661443140000
      });
    },
    onSearch() {
      this.$refs["alarm-list"].getList(true);
    }
  }
};
</script>

<style lang="scss" scoped>
.alarm-search-date {
  margin: 2px 12px;
}
</style>
