<template>
  <LoadListView
    v-model="loadStatus"
    :list="list"
    :total="total"
    :show-total-view="false"
    v-bind="$attrs"
    class="indicator-list"
    @load="getList"
  >
    <div
      v-for="item in list"
      :key="item.id"
      class="indicator-item"
      @click="gotoIndicatorDetail(item.id)"
    >
      <div class="title-contariner">
        <div class="indicator-name">
          {{ item.name }}{{ item.num ? `(${item.num})` : "" }}
        </div>
        <div
          v-if="item.value || item.value === 0"
          class="indicator-value"
          @click.stop="$emit('show-echart', item)"
        >
          {{
            item.value || item.value === 0
              ? item.value + item.indicatorUnitName
              : ""
          }}<i
            :class="{ active: item.handleStatus === 0 }"
            class="iconfont iconfont-lansezhexian"
          ></i>
        </div>
      </div>
      <div class="indicator-other-item">
        <span class="indicator-part"
          ><i class="iconfont iconfont-bumen"></i>
          {{ item.departmentName || "--" }}</span
        >
        <span class="device-info"
          ><i class="iconfont iconfont-quyu1"></i
          >{{ item.poiName || "--" }}</span
        >
        <span v-if="item.alarmGroupName" class="level-tag">{{
          item.alarmGroupName
        }}</span>
        <span v-if="item.envGroupName" class="dispose">{{
          item.envGroupName
        }}</span>
        <span v-if="item.major === 1" class="indicators">重点指标</span>
        <span v-if="['1', '2'].includes(item.sensorType)" class="dispose"
          >气体</span
        >
        <span
          v-if="item.levelCode"
          class="danger-source"
          :class="dangerLevelObj[item.levelCode]"
          >危险源</span
        >
      </div>
      <div class="indicator-other-item">
        <i class="iconfont iconfont-baojingyuzhi"></i>
        <span class="indicator-noun"
          >低低
          {{ item.lowLow ? item.lowLow + item.indicatorUnitName : "-" }}</span
        >
        <span class="indicator-noun"
          >低 {{ item.low ? item.low + item.indicatorUnitName : "-" }}</span
        >
        <span class="indicator-noun"
          >高 {{ item.high ? item.high + item.indicatorUnitName : "-" }}</span
        >
        <span class="indicator-noun"
          >高高
          {{
            item.highHigh ? item.highHigh + item.indicatorUnitName : "-"
          }}</span
        >
      </div>
    </div>
  </LoadListView>
</template>

<script>
export default {
  props: {
    api: {
      type: Function,
      default: () => Promise.resolve({ total: 0, list: [] })
    }
  },
  data() {
    return {
      loadStatus: "loading",
      query: {
        size: 10,
        page: 1
      },
      list: [],
      total: 0,
      error: false
    };
  },
  computed: {
    dangerLevelObj() {
      return {
        100311: "level1",
        100312: "level2",
        100313: "level3",
        100314: "level4"
      };
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    gotoIndicatorDetail(id) {
      this.$router.push({ name: "IndicatorDetail", params: { id } });
    },
    getList(refresh = false) {
      if (refresh) {
        this.query.page = 1;
        this.list = [];
        this.total = 0;
        this.loadStatus = "loading";
      }

      //待处理没有分页
      this.api(this.query)
        .then(({ list = [], total = 0 }) => {
          list.forEach(item => {
            item.value = !isNaN(item.value)
              ? parseFloat(item.value ? item.value.toFixed(2) : 0)
              : "";
          });
          this.list.push(...list);
          this.query.page++;
          this.total = total;
          this.loadStatus = "success";
        })
        .catch(error => {
          console.log(error);
          this.loadStatus = "error";
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.indicator-list {
  .indicator-item {
    position: relative;
    padding: 10px 16px 13px 16px;
    border-bottom: 8px solid #eceef2;
    .title-contariner {
      display: flex;
      margin-bottom: 10px;
      .indicator-name {
        flex: 1;
        font-size: 14px;
        color: #3b4664;
        font-weight: 500;
        line-height: 16px;
        word-break: break-all;
        padding: 0 12px 0 0;
      }
      .indicator-value {
        font-size: 14px;
        color: #3b4664;
        font-weight: 500;
        .iconfont-lansezhexian {
          width: 12px;
          height: 12px;
          color: #1676ff;
          border-radius: 50%;
          font-size: 12px;
          margin-left: 2px;
          &.active {
            color: #f13030;
          }
        }
      }
    }
    .indicator-other-item {
      font-size: 12px;
      color: #3b4664;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .iconfont {
        color: #aeb3c0;
        margin-right: 4px;
      }
      .indicator-part {
        margin-right: 20px;
        vertical-align: top;
      }
      .indicator-noun {
        padding: 0 2px;
        margin-right: 4px;
        &:not(:last-child) {
          position: relative;
          &::after {
            position: absolute;
            content: "";
            right: -2px;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 8px;
            background-color: #3b4664;
          }
        }
      }
      .device-info {
        display: inline-block;
        max-width: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: top;
      }
      .danger-source {
        position: relative;
        display: inline-block;
        transform: scale(0.9);
        padding: 2px 4px;
        font-size: 12px;
        margin-left: 8px;
        vertical-align: top;
        top: 1px;
        border-radius: 2px;
        &.level1 {
          border: 1px solid #f13030;
          color: #f13030;
          background-color: #ffdddd;
        }
        &.level2 {
          border: 1px solid #ff6d1e;
          color: #ff6d1e;
          background-color: #ffe7db;
        }
        &.level3 {
          border: 1px solid #edc400;
          color: #edc400;
          background-color: #fff8d7;
        }
        &.level4 {
          border: 1px solid #1580ff;
          color: #1580ff;
          background-color: #dcecff;
        }
      }
      .level-tag,
      .dispose,
      .indicators {
        position: relative;
        display: inline-block;
        border-radius: 2px;
        background-color: #e4fcd9;
        color: #3f9f11;
        margin-left: 4px;
        transform: scale(0.9);
        padding: 2px 4px;
        font-size: 12px;
        margin-left: 5px;
        top: 1px;
        border: 1px solid #3f9f11;
      }
      .level-tag {
        background-color: #e1cfff;
        color: #7839e0;
        border-color: #7839e0;
      }
      .indicators {
        color: #d73bbe;
        background: #ffe0ff;
        border: 1px solid #d73bbe;
      }
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}
</style>
