<template>
  <div class="switching-query">
    <div
      v-for="(item, idx) in configs"
      :key="idx"
      class="switching-query__item"
      @click="onSearchClick(item)"
    >
      <span class="switching-query__item-title">{{ item.title }}</span>
      <van-icon
        name="zhankai"
        class-prefix="iconfont"
        class="switching-query__item-icon"
      />
    </div>
    <select-popup
      :choose-value="popupValue"
      :data="popupData"
      :title="popupTitle"
      :visible="popupVisible"
      @close="onPopupClose"
      @confirm="onPopupConfirm"
    />
  </div>
</template>

<script>
import SelectPopup from "@/components/SelectPopup";
import { getInterlockLevels } from "../../api";
const DECOMMISSIONS = [
  {
    label: "未投用",
    value: 0
  },
  {
    label: "已投用",
    value: 1
  }
];

export default {
  name: "WorkQuery",
  components: { SelectPopup },
  props: {
    query: Object,
    queryType: {
      type: String,
      default: "job",
      validate(value) {
        // job 是作业许可，gas 是气体检测
        return ["job", "gas"].indexOf(value) !== -1;
      }
    }
  },
  data() {
    return {
      config: {},

      popupVisible: false,

      chainLevels: [],

      configs: [
        {
          title: "联锁等级",
          prop: "chainLevel",
          dataProp: "chainLevels"
        },
        {
          title: "状态",
          prop: "useStatus",
          dataProp: "decommissions"
        }
      ]
    };
  },
  computed: {
    decommissions: () => DECOMMISSIONS,
    popupTitle() {
      return this.config.title;
    },
    popupData() {
      return this[this.config.dataProp] || [];
    },
    popupValue() {
      return this.query[this.config.prop];
    }
  },
  created() {
    getInterlockLevels({ orgCode: this.userInfo.orgCode }).then(res => {
      this.chainLevels = res || [];
    });
  },
  methods: {
    refreshQuery() {
      this.$emit("refresh");
    },
    onSearchClick(item) {
      this.popupVisible = true;
      this.config = item;
    },
    onPopupClose() {
      this.popupVisible = false;
    },
    onPopupConfirm(row) {
      if (!this.config.prop) return;
      this.query[this.config.prop] = row.value;
      this.refreshQuery();
    }
  }
};
</script>

<style lang="scss" scoped>
.switching-query {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  margin-top: -2px;
  color: #3b4664;
  font-size: 14px;
  position: relative;
  &:after {
    position: absolute;
    bottom: 0;
    content: "";
    height: 1px;
    width: 100%;
    left: 0;
    border-bottom: 0.5px solid #c7c9d0;
    transform: scaleY(0.5);
  }
  &__item {
    flex: 1;
    display: flex;
    align-items: center;
    &:nth-child(2),
    &:nth-child(3) {
      justify-content: center;
    }
    &:nth-child(4) {
      justify-content: flex-end;
    }
    &-icon {
      margin-left: 4px;
      color: #aeb3c0;
      font-size: 12px;
    }
  }
}
</style>
