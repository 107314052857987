<template>
  <div>
    <van-search
      v-model="searchData.chainCircuitName"
      placeholder="请输入联锁回路名称"
      @search="onSearch"
      @clear="onSearch"
    >
      <template #left-icon>
        <van-icon name="sousuo" class-prefix="iconfont" class="search-icon" />
      </template>
    </van-search>

    <van-search
      v-model="searchData.chainCircuitBit"
      placeholder="请输入联锁回路位号"
      @search="onSearch"
      @clear="onSearch"
    >
      <template #left-icon>
        <van-icon name="sousuo" class-prefix="iconfont" class="search-icon" />
      </template>
    </van-search>

    <InterlockSearch :query="searchData" @refresh="onSearch" />

    <InterlockList ref="interlock-list" :api="getInterlockList" />
  </div>
</template>

<script>
import InterlockList from "../../components/InterlockList.vue";
import InterlockSearch from "./InterlockSearch.vue";

import { getInterlockList } from "../../api";
export default {
  components: {
    InterlockList,
    InterlockSearch
  },
  data() {
    return {
      searchData: {
        chainCircuitName: "",
        chainCircuitBit: "",
        chainLevel: "",
        useStatus: ""
      }
    };
  },
  methods: {
    getInterlockList(query = {}) {
      return getInterlockList({
        ...this.searchData,
        ...query,
        dangerId: this.$route.params.id
      });
    },
    onSearch() {
      this.$refs["interlock-list"].getList(true);
    }
  }
};
</script>
