<template>
  <LoadListView
    v-model="loadStatus"
    :list="list"
    :total="total"
    :show-total-view="false"
    v-bind="$attrs"
    @load="getList"
  >
    <div
      v-for="(item, idx) in list"
      :key="idx"
      class="security-item"
      @click="gotoDetails(item)"
    >
      <div class="security-left">
        <div class="security-title">
          {{ item.departmentName }}
          <span class="security-status" :style="configs[item.status]">{{
            item.statusValue
          }}</span>
        </div>

        <div class="security-span">上次承诺时间: {{ item.promiseDate }}</div>
      </div>
      <div class="security-right">
        <van-button
          v-if="item.repromise === 1"
          type="info"
          class="security-button"
          @click.stop="onPromise(item, 0)"
          >承诺</van-button
        >
        <van-button
          v-if="+item.repromise === 2"
          type="info"
          class="security-button"
          @click.stop="onPromise(item, 1)"
          >补录</van-button
        >
      </div>
    </div>
  </LoadListView>
</template>

<script>
import { isPromiseDataPromised } from "@/api/psm";
import { operateDialog } from "@/utils";

const configs = {
  0: {
    color: "#F6790F",
    backgroundColor: "rgba(246, 121, 15, 0.1)",
    borderColor: "rgba(246, 121, 15, 0.4)"
  },
  1: {
    color: "#F6790F",
    backgroundColor: "rgba(246, 121, 15, 0.1)",
    borderColor: "rgba(246, 121, 15, 0.4)"
  }
};
export default {
  props: {
    api: {
      type: Function,
      default: () => Promise.resolve({ total: 0, list: [] })
    }
  },
  data() {
    return {
      loadStatus: "loading",
      query: {
        size: 10,
        page: 1
      },
      list: [],
      total: 0,
      error: false
    };
  },
  computed: {
    configs: () => configs
  },
  mounted() {
    this.getList();
  },
  methods: {
    async gotoDetails({ id }) {
      await this.$router.push({ name: "PromiseDetails", params: { id } });
    },
    getList(refresh = false) {
      if (refresh) {
        this.query.page = 1;
        this.list = [];
        this.total = 0;
        this.loadStatus = "loading";
      }

      //待处理没有分页
      this.api(this.query)
        .then(({ list = [], total = 0 }) => {
          this.list.push(...list);
          this.query.page++;
          this.total = total;
          this.loadStatus = "success";
        })
        .catch(error => {
          console.log(error);
          this.loadStatus = "error";
        });
    },
    async onPromise({ id }, replenish = "0") {
      try {
        const isNext = await isPromiseDataPromised(id);
        if (isNext) {
          await operateDialog({
            title: "填报确认",
            message:
              "下级部门未全部完成承诺，请等下级部门全部完成承诺后再进行承诺？",
            showConfirmButton: false
          });
        } else {
          const query = { replenish };
          await this.$router.push({
            name: "PromiseFill",
            params: { id },
            query
          });
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.security {
  &-item {
    padding: 10px 16px;
    border-bottom: 8px solid #eceef2;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-title {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2e2e4d;
    line-height: 20px;
  }

  &-span {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a0a2a6;
    line-height: 17px;
    margin-top: 4px;
  }

  &-status {
    padding: 0 4px;
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 16px;
    border-radius: 2px;
    border: 1px solid transparent;
  }

  &-button {
    width: 72px;
    height: 32px;
    line-height: 35px;
    text-align: center;
    font-size: 14px;
    color: #ffffff;
    background: #1676ff;
    border-radius: 16px;
    .van-button__text {
      height: 32px;
    }
  }
}
</style>
