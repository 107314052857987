<template>
  <LoadListView
    v-model="loadStatus"
    :list="list"
    :total="total"
    :show-total-view="false"
    v-bind="$attrs"
    @load="getList"
  >
    <div
      v-for="item in list"
      :key="item.id + item.handleStatus"
      class="alarm-item"
      @click="gotoAlarmDetail(item.id)"
    >
      <div class="alarm-header">
        <div class="alarm-title">
          <div class="alarm-name-box">
            <img
              v-if="item.handleStatus === '0'"
              class="iconfont-baojingyuzhi"
              src="~@/assets/images/alarm.png"
            />
            <span class="alarm-name"
              >{{ item.indicatorName }} ({{ item.indicatorNum }})</span
            >
          </div>
          <div class="status-box">
            <img class="second-icon" src="~@/assets/images/equipment.png" />
            <span class="equipment-name">{{ item.equipmentName || "-" }}</span>
            <span class="status-color-box">{{ item.alarmStatusName }}</span>
            <span class="status-color-box type-color">{{
              item.indicatorTypeName
            }}</span>
          </div>
        </div>
        <div
          class="alarm-status"
          :style="`color: ${disposalStatusIconColorSwitch[item.checkStatus]}`"
        >
          <van-icon
            :name="disposalStatusImgSrcSwitch(item.checkStatus)"
            class-prefix="iconfont"
            class="trouble-status-icon"
          />
        </div>
      </div>
      <div class="alarm-time">
        <span>
          <img class="second-icon" src="~@/assets/images/date.png" />
          <span class="date-text">{{ item.firstAlarmTime }}</span>
        </span>
        <span>
          <img class="second-icon" src="~@/assets/images/time.png" />
          <span class="date-text">{{ item.alarmTime }}</span>
        </span>
      </div>
    </div>
  </LoadListView>
</template>

<script>
export default {
  props: {
    api: {
      type: Function,
      default: () => Promise.resolve({ total: 0, list: [] })
    }
  },
  data() {
    return {
      loadStatus: "loading",
      query: {
        size: 10,
        page: 1
      },
      list: [],
      total: 0,
      error: false
    };
  },
  computed: {
    disposalStatusImgSrcSwitch() {
      return checkStatus => {
        const obj = {
          0: "yihulue",
          1: "daiqueren",
          2: "daichuzhi",
          3: "daiheyan",
          4: "yiwancheng"
        };
        return obj[checkStatus];
      };
    },
    disposalStatusIconColorSwitch() {
      return {
        0: "#aeb3c0",
        1: "#5c7ba2",
        2: "#dea148",
        3: "#6448d7",
        4: "#8eb433"
      };
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    gotoAlarmDetail(id) {
      this.$router.push({ name: "AlarmDetail", params: { id } });
    },
    getList(refresh = false) {
      if (refresh) {
        this.query.page = 1;
        this.list = [];
        this.total = 0;
        this.loadStatus = "loading";
      }

      //待处理没有分页
      this.api(this.query)
        .then(({ list = [], total = 0 }) => {
          this.list.push(...list);
          this.query.page++;
          this.total = total;
          this.loadStatus = "success";
        })
        .catch(error => {
          console.log(error);
          this.loadStatus = "error";
        });
    }
  }
};
</script>

<style scoped lang="scss">
.alarm-item {
  padding: 11px 17px 13px 16px;
  border-bottom: 8px solid #eceef2;
  font-size: 4vw;
  .alarm-header {
    display: flex;
    .alarm-title {
      flex: 3;
      max-width: 70vw;
      div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 1.1;
      }
      color: #3b4664;
      .alarm-name-box {
        margin: 0 0 10px;
        .iconfont-baojingyuzhi {
          margin: 0 4px 0 0;
          vertical-align: sub;
        }
        .alarm-name {
          height: 20px;
          font-size: 14px;
          font-weight: 500;
          color: #3b4664;
          line-height: 20px;
        }
      }
      .status-box {
        padding: 0 0 0 3px;
        margin: 0 0 6px;
        .equipment-name {
          font-size: 12px;
          font-weight: 400;
          color: #3b4664;
          line-height: 17px;
          margin: 0 20px 0 0;
        }
        .status-color-box {
          display: inline-block;
          font-size: 11px;
          font-weight: 400;
          color: #8b4fee;
          line-height: 16px;
          padding: 0 6px 0;
          margin: 0 8px 0 0;
          background: #ede2ff;
          border: 1px solid #8b4fee;
          border-radius: 3px;
        }
        .type-color {
          color: #00b6d6;
          background: #d8feff;
          border: 1px solid #00b6d6;
        }
      }
      .second-icon {
        margin: 0 4px 0 0;
        vertical-align: middle;
      }
    }
    .alarm-status {
      flex: 1;
      /*font-size: 100vw;*/
      text-align: right;
      padding: 7px 0 0;

      .trouble-status-icon {
        display: inline-block;
        text-align: center;
        width: 45px;
        height: 45px;
        line-height: 45px;
        font-size: 40px;
        &::after {
          display: inline-block;
          width: inherit;
          height: inherit;
        }
      }
    }
  }
  .alarm-time {
    color: #3b4664;
    padding: 0 0 0 3px;
    .second-icon {
      margin: 0 4px 0 0;
      vertical-align: middle;
    }
    .date-text {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      margin: 0 20px 0 0;
    }
  }
}
</style>
