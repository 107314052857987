<template>
  <div>
    <van-search
      v-model="searchData.fuzzyName"
      placeholder="请输入点位名称或工艺位号"
      @search="onSearch"
      @clear="onSearch"
    >
      <template #left-icon>
        <van-icon name="sousuo" class-prefix="iconfont" class="search-icon" />
      </template>
    </van-search>
    <MonitorList
      ref="monitor-list"
      :api="getSensorList"
      @show-echart="showEchart"
    />

    <IndicatorEchartsPopup v-model="visible" :model="model" />
  </div>
</template>

<script>
import MonitorList from "../../components/MonitorList.vue";
import IndicatorEchartsPopup from "@/views/indicator/components/indicatorEchartsPopup.vue";

import { getSensorList } from "../../api";

const modelKeys = [
  "id",
  "name",
  "num",
  "value",
  "collectTime",
  "indicatorTypeName",
  "threshold",
  "alarmName",
  "alarmTime",
  "departmentName",
  "poiName",
  "dangerName",
  "dutyMobile",
  "hseHeader",
  "hsdMobile",
  "low",
  "lowLow",
  "high",
  "highHigh",
  "indicatorUnitName"
];
export default {
  components: {
    MonitorList,
    IndicatorEchartsPopup
  },
  data() {
    return {
      searchData: {
        fuzzyName: ""
      },
      visible: false,
      model: {}
    };
  },
  methods: {
    getSensorList(query = {}) {
      return getSensorList({
        ...this.searchData,
        ...query,
        dangerIdList: this.$route.params.id
      });
    },
    onSearch() {
      this.$refs["monitor-list"].getList(true);
    },
    showEchart(row) {
      const obj = {};
      modelKeys.forEach(key => {
        obj[key] = row[key] || "--";
      });
      this.model = obj;
      this.visible = true;
    }
  }
};
</script>
