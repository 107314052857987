<template>
  <LoadListView
    v-model="loadStatus"
    :list="list"
    :total="total"
    :show-total-view="false"
    v-bind="$attrs"
    @load="getList"
  >
    <div
      v-for="(item, idx) in list"
      :key="idx"
      class="interlock-item"
      @click="gotoDetails(item)"
    >
      <div class="interlock-title">
        {{ item.chainAccountVo.chainCircuitName }}
        <span
          class="interlock-status"
          :class="{ 'is-used': item.chainAccountVo.useStatus === 1 }"
          >{{
            statusMap[item.chainAccountVo.useStatus] &&
              statusMap[item.chainAccountVo.useStatus].label
          }}</span
        >
      </div>

      <div class="interlock-body">
        <span class="interlock-span">{{
          item.chainAccountVo.departmentName
        }}</span>
        <span class="interlock-span">{{
          item.chainAccountVo.chainSystem === "1"
            ? "其他"
            : item.chainAccountVo.chainSystem
        }}</span>
        <span class="interlock-span"
          >联锁等级: {{ item.chainAccountVo.chainLevelName }}</span
        >
      </div>

      <div
        v-if="item.circuitBitVoList && item.circuitBitVoList.length"
        class="interlock-tags"
      >
        <div
          v-for="(tag, tIndex) in item.circuitBitVoList"
          :key="tIndex"
          class="interlock-tag"
        >
          {{ tag.chainCircuitBit }}
        </div>
      </div>
    </div>
  </LoadListView>
</template>

<script>
const statusMap = {
  0: {
    label: "未投用"
  },
  1: {
    label: "已投用"
  }
};
export default {
  props: {
    api: {
      type: Function,
      default: () => Promise.resolve({ total: 0, list: [] })
    }
  },
  data() {
    return {
      loadStatus: "loading",
      query: {
        size: 10,
        page: 1
      },
      list: [],
      total: 0,
      error: false
    };
  },
  computed: {
    statusMap: () => statusMap
  },
  mounted() {
    this.getList();
  },
  methods: {
    gotoDetails(item) {
      this.$router.push({
        name: "danger-interlock-account-detail",
        params: { id: item.chainAccountVo.id }
      });
    },
    getList(refresh = false) {
      if (refresh) {
        this.query.page = 1;
        this.list = [];
        this.total = 0;
        this.loadStatus = "loading";
      }

      //待处理没有分页
      this.api(this.query)
        .then(({ list = [], total = 0 }) => {
          this.list.push(...list);
          this.query.page++;
          this.total = total;
          this.loadStatus = "success";
        })
        .catch(error => {
          console.log(error);
          this.loadStatus = "error";
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.interlock {
  &-item {
    padding: 11px 16px;
    border-bottom: 8px solid #eceef2;
    background-color: #fff;
  }

  &-title {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2e2e4d;
    line-height: 20px;
  }

  &-body {
    margin-top: 4px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a0a2a6;
    line-height: 17px;
  }

  &-status {
    padding: 0 4px;
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 16px;
    border-radius: 2px;

    background: $--color-primary;
    &.is-used {
      background: #f6790f;
    }
  }

  &-span {
    border-left: 1px solid #a0a2a6;
    padding: 0 10px;

    &:nth-of-type(1) {
      padding-left: 0;
      border: none;
    }

    &:nth-last-of-type(1) {
      padding-right: 0;
    }
  }

  &-tags {
    margin-top: 6px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &-tag {
    padding: 0 4px;
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3278ff;
    line-height: 16px;
    background: rgba(50, 120, 255, 0.1);
    border-radius: 2px;
    margin-right: 4px;
  }
}
</style>
