<template>
  <div>
    <SecuritySearch :query="searchData" @refresh="onSearch" />
    <SecurityList ref="security-list" :api="getSecurityList" />
  </div>
</template>

<script>
import SecurityList from "../../components/SecurityList.vue";
import SecuritySearch from "./SecuritySearch.vue";

import { getSecurityList } from "../../api";
export default {
  components: {
    SecurityList,
    SecuritySearch
  },
  data() {
    return {
      searchData: {
        status: "",
        sort: ""
      }
    };
  },
  methods: {
    getSecurityList(query = {}) {
      return getSecurityList({
        ...this.searchData,
        ...query,
        dangerId: this.$route.params.id
      });
    },
    onSearch() {
      this.$refs["security-list"].getList(true);
    }
  }
};
</script>
